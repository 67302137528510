var firebaseConfig = {
  apiKey: "AIzaSyCyxU6oq7IHe7ePpP638rLGd2bWvs7j_Bo",
  authDomain: "huntr-4310a.firebaseapp.com",
  databaseURL: "https://huntr-4310a.firebaseio.com",
  projectId: "huntr-4310a",
  storageBucket: "huntr-4310a.appspot.com",
  messagingSenderId: "8451791540",
  appId: "1:8451791540:web:d2f9b8886b69d615ed95e1",
};

export default firebaseConfig;
